import { useEffect, useState } from "react";
import { selectAllCategory } from "../api-services/job-category";

export function useFetchJobCategory() {
  const [jobCategories, setJobCategory] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    selectAllCategory()
      .then((jobCategoryData) => {
        if (jobCategoryData.status === "success") {
          const ModifyData = jobCategoryData?.data?.map((data) => {
            const name =
              data?.name === "Tire & Wheel Technician" ||
              data?.name === "Tow Truck Operator"
                ? data?.name
                : `${data.name} Mechanic`;
            return {
              name: name,
              value: data.name,
            };
          });
          setJobCategory(ModifyData);
        }
      })
      .catch((error) => {
        setError(error?.message);
      });
  }, []);

  return {
    jobCategories,
    error,
  };
}
