import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { editJobInfo, postJob } from "../../../api-services/jobs";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";
import { haveValue } from "../../../utils/helpers";
import CmsTextEditor from "../../CmsTextEditor";

const StepTwo = ({ setCurrentStep, jobFetchData, onUpdateJob, edit, isClone = false }) => {

  const navigate = useNavigate();

  const [IsSaving, setIsSaving] = useState(false);

  const schema = yup.object().shape({
    rate: yup.object().shape({
      rateValue: yup.number().optional(),
      min: yup.number().notRequired(),
      max: yup.number().notRequired(),
      rateTimeLine: yup.string().required("Rate timeline is required"),
      rateType: yup.string().required("Rate type is required"),
    }),
    summary: yup.string().required("Job summary is required"),
    specifics: yup.string().required("Job Specific Requirement is required"),
    qualifications: yup.object().shape({
      education: yup.string().required("Qualifications is required"),
      experience: yup.string().required("Job Experiment is required"),
    }),
    // whyApply: yup.string().required("why apply section is required"),
    // aboutCompany: yup.string().required("about section is required"),
  });

  const initialFormValues = {
    ...jobFetchData,
    rate: {
      rateType: jobFetchData?.rate?.rateType || "range",
      rateValue: jobFetchData?.rate?.rateValue || 0, // Initialize as null
      min: jobFetchData?.rate?.min || 0, // Initialize as null
      max: jobFetchData?.rate?.max || 0, // Initialize as null
      rateTimeLine: jobFetchData?.rate?.rateTimeLine || "",
    },
    summary: jobFetchData?.summary || "",
    specifics: jobFetchData?.specifics || "",
    // whyApply: jobFetchData?.whyApply,
    // aboutCompany: jobFetchData?.aboutCompany,
    qualifications: {
      education: jobFetchData?.qualifications?.education || "",
      experience: jobFetchData?.qualifications?.experience || ""
    },
    // ...other fields
  };

  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const handleBack = () => {
    onUpdateJob(getValues());
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const editJobServices = (data) => {
    console.log("edit", jobFetchData)
    if (haveValue(jobFetchData._id) && edit) {
      const nData = data;
      delete nData._id;
      data = { id: jobFetchData._id, ...nData };
      setIsSaving(true);
      editJobInfo(data)
        .then((jobEditRes) => {
          setIsSaving(false);
          if (jobEditRes?.status === "success") {
            toast.success("Job Edit Successfully");
            reset();
            navigate("/jobs");
          }
        })
        .catch((error) => {
          setIsSaving(false);
          toast.error(error.message);
        });
    }
  };

  const RateType = watch("rate.rateType");
  const watchField = watch();

  const onSubmit = (data) => {
    if (data) {
      if (edit) {
        editJobServices(data);
      } else {
        setIsSaving(true);
        const nData = data;
        delete nData._id;
        postJob(nData)
          .then((data) => {
            setIsSaving(false);
            if (data.status === "success") {
              toast.success("Job post successfully");
              reset();
              navigate("/jobs");
            }
          })
          .catch((error) => {
            setIsSaving(false);
            toast.error(error?.message);
          });
      }
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  const { _t } = useTranslator();

  useEffect(() => {
    if (haveValue(jobFetchData) && (edit || isClone)) {

      console.log("jobFetchData", jobFetchData);

      const jobNFields = {
        ...jobFetchData,
        rate: {
          max: jobFetchData?.rate?.max,
          min: jobFetchData?.rate?.min,
          rateType: jobFetchData?.rate?.rateType,
          rateTimeLine: jobFetchData?.rate?.rateTimeLine,
          rateValue: jobFetchData?.rate?.rateValue,
        },
        summary: jobFetchData?.summary,
        specifics: jobFetchData?.specifics,
        // whyApply: jobFetchData?.whyApply,
        // aboutCompany: jobFetchData?.aboutCompany,
        qualifications: {
          education: jobFetchData?.qualifications?.education,
          experience: jobFetchData?.qualifications?.experience
        },
      };

      console.log("jobNFields", jobNFields);

      reset(jobNFields);
    }
  }, [jobFetchData, edit, isClone]);

  return (
    <div className="block-bar">
      <Form className="mt-0" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="formrow">
          <div className="dflex-inputbar form-100">
            <Form.Label>Show pay by</Form.Label>

            <Form.Group className="forminput" controlId="range">
              <Form.Select
                {...register("rate.rateType")}
                className="form-control arrow-dropdown">
                <option value="range">Range</option>
                <option value="starting amount">starting amount</option>
                <option value="maximum amount">maximum amount</option>
                <option value="fixed amount">fixed amount</option>
              </Form.Select>
              <p className="error">
                {" "}
                {errors?.rate?.rateType && `${errors?.rate?.rateType.message}`}
              </p>
            </Form.Group>
          </div>

          <div className="render_row">
            {RateType === "range" ? (
              <>
                <div className="dflex-inputbar ">
                  <Form.Label>Minimum</Form.Label>
                  <Form.Group className="forminput" controlId="min">
                    <Form.Control
                      type="number"
                      placeholder="$ 100"
                      {...register("rate.min", {
                        valueAsNumber: true,
                      })}
                    />
                    <p className="error">
                      {" "}
                      {errors?.rate?.min && `${errors?.rate?.min.message}`}
                    </p>
                  </Form.Group>
                </div>
                <div className="dflex-inputbar ">
                  <Form.Label>Maximum</Form.Label>
                  <Form.Group className="forminput" controlId="max">
                    <Form.Control
                      type="number"
                      placeholder="$ 100"
                      {...register("rate.max", {
                        valueAsNumber: true,
                      })}
                    />
                    <p className="error">
                      {" "}
                      {errors?.rate?.max && `${errors?.rate?.max.message}`}
                    </p>
                  </Form.Group>
                </div>
              </>
            ) : (
              <div className="dflex-inputbar ">
                <Form.Label>Amount</Form.Label>
                <Form.Group className="forminput" controlId="rateValue">
                  <Form.Control
                    type="number"
                    placeholder="$ 100"
                    {...register("rate.rateValue", {
                      valueAsNumber: true,
                    })}
                  />
                  <p className="error">
                    {" "}
                    {errors?.rate?.rateValue &&
                      `${errors?.rate?.rateValue.message}`}
                  </p>
                </Form.Group>
              </div>
            )}

            <div className="dflex-inputbar">
              <Form.Label>Rate</Form.Label>
              <Form.Group className="forminput" controlId="rate.rateTimeLine">
                <Form.Select
                  aria-label="rateTimeLine"
                  {...register("rate.rateTimeLine")}
                  className="form-control arrow-dropdown">
                  <option value="">Basic pay range</option>
                  <option value="hourly">Hourly</option>
                  <option value="monthly"> Monthly</option>
                  <option value="weekly">Weekly</option>
                  <option value="daily">Daily</option>
                  <option value="yearly">Yearly</option>
                </Form.Select>
                <p className="error">
                  {" "}
                  {errors?.rate?.rateTimeLine &&
                    `${errors?.rate?.rateTimeLine.message}`}
                </p>
              </Form.Group>
            </div>
          </div>
          <div className="dflex-inputbar form-100">
            <Form.Label>{_t("Job Summary ")}</Form.Label>
            <CmsTextEditor
              defaultContent={getValues('summary')}
              onContentChange={(content) => {
                setValue('summary', content)
              }}
            />
            <p className="error">
              {" "}
              {errors?.summary &&
                `${errors?.summary.message}`}
            </p>
          </div>

          <div className="dflex-inputbar form-100">
            <div className="btnlignself">
              <Form.Label>Job Specific Requirement</Form.Label>
            </div>

            <CmsTextEditor
              defaultContent={getValues('specifics')}
              onContentChange={(content) => {
                setValue('specifics', content);
              }}
            />

            <p className="error">
              {" "}
              {errors?.specifics &&
                `${errors?.specifics.message}`}
            </p>
          </div>

          <div className="dflex-inputbar form-100">
            <div className="btnlignself">
              <Form.Label>{_t("Qualifications")}</Form.Label>
            </div>

            <CmsTextEditor
              defaultContent={getValues('qualifications.education')}
              onContentChange={(content) => {
                setValue('qualifications.education', content);
              }}
            />

            <p className="error">
              {" "}
              {errors?.qualifications?.education &&
                `${errors?.qualifications?.education.message}`}
            </p>
          </div>

          <div className="dflex-inputbar form-100">
            <div className="btnlignself">
              <Form.Label>{_t("Job Experiences Requirement")}</Form.Label>
            </div>

            <CmsTextEditor
              defaultContent={getValues('qualifications.experience')}
              onContentChange={(content) => {
                setValue('qualifications.experience', content);
              }}
            />

            <p className="error">
              {" "}
              {errors?.qualifications?.experience &&
                `${errors?.qualifications?.experience.message}`}
            </p>
          </div>

          {/* <div className="dflex-inputbar form-100">
            <Form.Label>{_t("Why apply for this job")}</Form.Label>
            <Form.Group className="forminput" controlId="whyApply">
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                placeholder="why apply for this job"
                {...register("whyApply")}
              />
              <p className="error">
                {" "}
                {errors?.whyApply && `${errors?.whyApply.message}`}
              </p>
            </Form.Group>
          </div>
          <div className="dflex-inputbar form-100">
            <Form.Label>About your company</Form.Label>
            <Form.Group className="forminput" controlId="aboutCompany">
              <Form.Control
                as="textarea"
                rows={4}
                type="text"
                placeholder="About your company"
                {...register("aboutCompany")}
              />
              <p className="error">
                {errors?.aboutCompany && `${errors?.aboutCompany?.message}`}
              </p>
            </Form.Group>
          </div> */}
        </div>
        <div className="btnform mt-2">
          {
            IsSaving ? <>

              <div className="btnbar">
                <button
                  className="btnorange btn"
                  type="button">
                  Please wait...
                </button>
              </div>

            </> : <>

              <div className="btnbar">
                <button
                  className="btnorange btn"
                  type="button"
                  onClick={handleBack}>
                  Back
                </button>
              </div>
              {edit ? (
                <div className="btnbar">
                  <button className="btnorange btn" type="submit">
                    Edit
                  </button>
                </div>
              ) : (
                <div className="btnbar">
                  <button className="btnorange btn" type="submit">
                    Post
                  </button>
                </div>
              )}

            </>
          }
        </div>
      </Form>
    </div>
  );
};

export default StepTwo;
