const config = {
  server: {
    // url: "http://localhost:7007/api",
    // imageBaseUrl: "http://localhost:7007/uploads",

    imageBaseUrl: "https://api.remotemechanicjobs.com/uploads",
    url: "https://api.remotemechanicjobs.com/api",
  },
  google: {
    GOOGLE_SITE_KEY: "6Lcg51QpAAAAAK5i5WpKWqwjZ_LPyHDY_o_ql-nf",
    GOOGLE_SITE_SECRET: "6Lcg51QpAAAAAN2OVU62GXG-GJUhZxy27sY2eJUV",
  },
  tinyMce: "8kcye3p0frd3ue0h97oy9l74kb2yb100g1kpj0qblmk7hcr3",
};


export default config;

export const GOOGLE_SITE_KEY = config.google.GOOGLE_SITE_KEY;
export const GOOGLE_SITE_SECRET = config.google.GOOGLE_SITE_SECRET;
export const EDITOR_API_KEY = config.tinyMce;
export const imageBaseUrl = config.server.imageBaseUrl;
export const blogCoverImageUrl = `${config.server.imageBaseUrl}`;
export const blogCoverImageDoc = `${config.server.imageBaseUrl}`;
