import React, { useEffect, useState } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import useTranslator from "../../../hooks/useTranslator";
import { useParams, useSearchParams } from "react-router-dom";
import { haveValue } from "../../../utils/helpers";
import { getUserSingleJob } from "../../../api-services/jobs";
const JobPost = ({ edit }) => {

  const [currentStep, setCurrentStep] = useState(1);
  const [jobFetchData, setJobInfo] = useState({});
  const [errorJobError, setJobError] = useState(null);
  const { _t } = useTranslator();
  const { id } = useParams();

  const [isClone, setIsClone] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const cloneJobId = searchParams.get("jobId");

  useEffect(() => {
    if (haveValue(id)) {
      getUserSingleJob({ jobId: id })
        .then((jobData) => {
          if (jobData) {
            setJobInfo(jobData?.data);
          }
        })
        .catch((error) => {
          console.error("Error while getting job info", error);
          setJobError(error);
        });
    } else {
      // If id is empty, reset the jobFetchData to null
      setJobInfo(null);
    }
  }, [id]);

  useEffect(() => {
    if (haveValue(cloneJobId)) {
      getUserSingleJob({ jobId: cloneJobId })
        .then((jobData) => {
          if (jobData) {
            setJobInfo(jobData?.data);
            setIsClone(true);
          }
        })
        .catch((error) => {
          console.error("Error while getting job info", error);
          setJobError(error);
        });
    }
  }, [cloneJobId])

  const handleUpdateJob = (jobData) => {
    const newJobData = {
      ...{
        _id: jobFetchData?._id || null,
        jobTitle: jobFetchData?.jobTitle || "",
        jobType: jobFetchData?.jobType || "",
        jobCategory: jobFetchData?.jobCategory || "",
        category: jobFetchData?.category || "",
        country: jobFetchData?.country || "",
        state: jobFetchData?.state || "",
        city: jobFetchData?.city || "",
        jobLanguage: jobFetchData?.jobLanguage || "",
        employmentType: jobFetchData?.employmentType || "",
        skills: jobFetchData?.skills || "",
        rate: {
          rateType: jobFetchData?.rate?.rateType || "range",
          rateValue: jobFetchData?.rate?.rateValue || 0,
          min: jobFetchData?.rate?.min || 0,
          max: jobFetchData?.rate?.max || 0,
          rateTimeLine: jobFetchData?.rate?.rateTimeLine || "",
        },
        summary: jobFetchData?.summary || "",
        specifics: jobFetchData?.specifics || "",
        // whyApply: jobFetchData?.whyApply,
        // aboutCompany: jobFetchData?.aboutCompany,
        qualifications: {
          education: jobFetchData?.qualifications?.education || "",
          experience: jobFetchData?.qualifications?.experience || ""
        },
      },
      ...jobData
    };
    console.log(newJobData);
    setJobInfo(newJobData);
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <StepOne
              setCurrentStep={setCurrentStep}
              edit={edit}
              isClone={isClone}
              jobFetchData={jobFetchData}
              onUpdateJob={handleUpdateJob}
            />
          </>
        );
      case 2:
        return (
          <>
            <StepTwo
              setCurrentStep={setCurrentStep}
              edit={edit}
              isClone={isClone}
              jobFetchData={jobFetchData}
              onUpdateJob={handleUpdateJob}
            />
          </>
        );

      default:
        return null;
    }
  };
  return (
    <div className="container">
      <div className="block-bar">
        <h1 className="heading">{_t("Publish Job")}</h1>
        <div className="step-form-container">{renderStepContent()}</div>
      </div>
    </div>
  );
};

export default JobPost;
