import 'draft-js/dist/Draft.css';
import "../src/Css/style.css";
import "../src/fonts/stylesheet.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "./hooks/useThemeContext";
import AppRouter from "./AppRouter";
import { Toaster } from "react-hot-toast";
import { CookieConsentProvider } from "@use-cookie-consent/react";
import { LocalLang } from "./utils/helpers";
import { useEffect } from "react";

function App() {
  const supportLang = LocalLang();

  useEffect(() => {
    document.documentElement.setAttribute("lang", supportLang);
  }, []);

  return (
    <>
      <CookieConsentProvider>
        <ThemeProvider>
          <Router basename={`/${supportLang}`}>
            <AppRouter />
            <Toaster position="top-right" reverseOrder={false} />
          </Router>
        </ThemeProvider>
      </CookieConsentProvider>
    </>
  );
}

export default App;
