import React, { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useTranslator from "../../../hooks/useTranslator";
import { StateList, haveValue } from "../../../utils/helpers";
import { useFetchJobCategory } from "../../../data-fetch/useJobCategory";
import { USERS_ROLES } from "../../../constants/user";
import useStateAndCity from "../../../data-fetch/useStateAndCity"; 

const StepOne = ({ setCurrentStep, edit, jobFetchData, onUpdateJob, isClone = false }) => {

  const [selectedCountry, setSelectedCountry] = useState("US");
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [jobEditData, setJobEditData] = useState(null);

  const handleNext = () => {
    setCurrentStep((prevStep) => (prevStep < 8 ? prevStep + 1 : prevStep));
  };

  const { jobCategories } = useFetchJobCategory();

  const { } = useTranslator();

  const schema = yup
    .object()
    .shape({
      jobTitle: yup.string().required(),
      jobType: yup.string().required(),
      jobCategory: yup.string().required(),
      country: yup.string(),
      state: yup.string().required(),
      city: yup.string().notRequired(),
      jobLanguage: yup.string().required(),
      category: yup.string().required(),
      employmentType: yup.string().required(),
      category: yup.string().required(),
      skills: yup.string().required(),
    })
    .required();

  const {
    fetchCities,
    stateList: stateListMap,
    cityList,
    loading,
  } = useStateAndCity();

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: jobFetchData,
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const onSubmit = (data) => {
    if (data) {
      data = {
        ...data,
        country: data.country ? data.country : selectedCountry,
      };
      onUpdateJob(data);
      handleNext();
    }
  };

  const onError = (errors, e) => console.log(errors, "Error", e);

  useEffect(() => {
    if (haveValue(jobFetchData) && (edit || isClone)) {
      console.log("🚀 ~ useEffect ~ jobFetchData:", jobFetchData);
      setSelectedState(jobFetchData?.state);
      setSelectedCity(jobFetchData.city);
      reset({
        jobTitle: jobFetchData?.jobTitle,
        jobType: jobFetchData?.jobType,
        jobCategory: jobFetchData?.jobCategory,
        category: jobFetchData?.category,
        country: jobFetchData?.country,
        state: jobFetchData?.state,
        city: jobFetchData?.city || "",
        jobLanguage: jobFetchData?.jobLanguage,
        employmentType: jobFetchData?.employmentType,
        skills: jobFetchData?.skills,
      });

      const filterState = StateList.find(
        (state) => state.name === jobFetchData.state
      );

      setSelectedState(filterState?.iso);
      setSelectedCity(jobFetchData?.city);

      fetchCities(filterState?.iso);
    }
  }, [jobFetchData, edit, isClone]);

  const handleState = (e) => {
    const value = e.target.value;
    console.log("🚀 ~ StepSeven ~ value:", value);
    // console.log(value)
    // console.log(StateList)
    // console.log(stateListMap)
    const filterState = stateListMap.find((st) => st.isoCode === value);
    if (filterState) {
      fetchCities(value);
      console.log("🚀 ~ handleState ~ filterState:", filterState);
      setValue("state", filterState.name);
      setSelectedState(value);
    }
  };

  return (
    <section className="block-bar">
      <Form className="mt-0" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="formrow">
          <div className="dflex-inputbar form-100">
            <Form.Group className="forminput" controlId="Name">
              <Form.Control
                type="text"
                placeholder="Job title"
                {...register("jobTitle")}
              />
              <p className="error">
                {" "}
                {errors.jobTitle && errors.jobTitle.message}
              </p>
            </Form.Group>
          </div>
          <div className="dflex-inputbar form-50">
            <Form.Group className="forminput" controlId="jobType">
              <Form.Select
                aria-label="Select job Type"
                {...register("jobType")}
                className="form-control arrow-dropdown">
                <option>
                  {"Which option best describes this job's location? "}
                </option>
                <option value="on-site">{"Onsite"}</option>
                <option value="remote">{"Remote"}</option>
              </Form.Select>
            </Form.Group>
          </div>

          <div className="dflex-inputbar form-50">
            <Form.Group className="forminput" controlId="jobCategory">
              <Form.Select
                aria-label="Select job Niche"
                {...register("jobCategory")}
                className="form-control arrow-dropdown">
                <option>{"Select Job Niche"}</option>
                {jobCategories?.map((category) => (
                  <option value={category?.value} key={category?.value}>
                    {category.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <p className="error">
              {" "}
              {errors.jobCategory && `${errors.jobCategory.message}`}
            </p>
          </div>
          <div className="dflex-inputbar form-50">
            <Form.Group className="forminput" controlId="jobLanguage">
              <Form.Select
                aria-label="Select job Language"
                {...register("jobLanguage")}
                className="form-control arrow-dropdown">
                <option>{"Select Job Language"}</option>

                <option value="English">{"English"}</option>
                <option value="Spanish">{"Spanish"}</option>
              </Form.Select>
            </Form.Group>
            <p className="error">
              {" "}
              {errors.jobLanguage && `${errors.jobLanguage.message}`}
            </p>
          </div>
          <div className="dflex-inputbar form-50">
            <Form.Group className="forminput" controlId="category">
              <Form.Select
                aria-label="Job For"
                {...register("category")}
                className="form-control arrow-dropdown">
                <option>{"Select Job Category"}</option>

                <option value={USERS_ROLES.shop_owner}>{"Shop Owner"}</option>
                <option value={USERS_ROLES.sole_proprietor}>
                  {"Mechanic"}
                </option>
              </Form.Select>
            </Form.Group>
            <p className="error">
              {" "}
              {errors.category && `${errors.category.message}`}
            </p>
          </div>
          <div className="dflex-inputbar form-50">
            <Form.Group className="forminput" controlId="employmentType">
              <Form.Select
                aria-label="Select job Language"
                {...register("employmentType")}
                className="form-control arrow-dropdown">
                <option>{"Employment Type"}</option>

                <option value="FULL_TIME">{"Full time"}</option>
                <option value="PART_TIME">{"Part Time"}</option>
                <option value="CONTRACTOR">{"CONTRACTOR"}</option>
                <option value="INTERN">{"INTERN"}</option>
              </Form.Select>
            </Form.Group>
            <p className="error">
              {" "}
              {errors.employmentType && `${errors.employmentType.message}`}
            </p>
          </div>

          <div className="dflex-inputbar form-50">
            <Form.Group className="forminput" controlId="state">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => handleState(e)}
                value={selectedState}
                className="form-control arrow-dropdown">
                <option>State</option>
                {stateListMap?.map((item) => (
                  <option value={item.isoCode} key={item.isoCode}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>

              <p className="error">
                {" "}
                {errors.state && `${errors.state.message}`}
              </p>
            </Form.Group>
          </div>

          <div className="dflex-inputbar form-50">
            <Form.Group className="forminput" controlId="city">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setSelectedCity(e.target.value);
                  setValue("city", e.target.value);
                }}
                value={selectedCity}
                className="form-control arrow-dropdown">
                <option>City</option>
                {cityList?.map((item) => (
                  <option value={item.name} key={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>

              <p className="error">
                {" "}
                {errors?.city && `${errors?.city?.message}`}
              </p>
            </Form.Group>
          </div>

          <div className="dflex-inputbar form-100">
            <Form.Group className="forminput" controlId="skills">
              <Form.Control
                type="text"
                placeholder="Required Skills Ex. Mechanical Skills, Braking system repair, Oil changes etc..."
                {...register("skills")}
              />
              <p className="error">
                {" "}
                {errors.skills && `${errors.skills.message}`}
              </p>
            </Form.Group>
          </div>

          <div className="dflex-inputbar form-100">
            <div className="dflex-bar">
              <div className="btnbar">
                <button className="btnorange btn" type="submit">
                  {"Next"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </section>
  );
};

export default StepOne;
