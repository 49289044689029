import { convertToRaw, EditorState, ContentState, convertFromHTML } from "draft-js";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

const CmsTextEditor = ({ defaultContent = "", onContentChange }) => {
  const [editorState, setEditorState] = useState(() => {
    const blocksFromHTML = convertFromHTML(defaultContent || "");
    return EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHTML));
  });

  useEffect(() => {
    const currentContentHTML = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();
    if (defaultContent.trim() !== currentContentHTML) {
      const blocksFromHTML = convertFromHTML(defaultContent || "");
      const contentState = ContentState.createFromBlockArray(blocksFromHTML);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [defaultContent, editorState]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    if (onContentChange) {
      onContentChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'remove', 'history'],
      }}
    />
  );
};

export default CmsTextEditor;
